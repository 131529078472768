<template>
  <HeaderComponent :section-class="'hero-section-home'" :show-text="true" />
  <div
    class="container-fluid px-4 py-5 bg-dark text-white d-flex justify-content-center"
  >
    <div class="col-xxl-8 col-12">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div class="col-10 col-sm-8 col-lg-6">
          <img
            src="../assets/images/aboutmainpage.jpg"
            class="d-block mx-lg-auto img-fluid border"
            alt="Keith Shimp"
            loading="lazy"
          />
        </div>
        <div class="col-lg-6">
          <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">
            About Me
          </h1>
          <p class="lead">
            Thanks for visiting!
            <br /><br />
            My name is Keith and I've been writing software as long as I can
            remember! I grew up making simple websites, getting my feet wet with
            PHP/MySQL driven websites. I made a YouTube clone when I was a
            freshman in high-school, and after that I was hooked. Fast forward
            to today, and I have over 5 years of professional, cutting edge
            industry experience working on applications used by thousands of
            people. My overall goal is to help others reach their goals while
            continuing to learn more and reach my own.
            <br /><br />
            I currently live in Iowa with my wife and two dogs, Laurel and
            Merlin.
            <br /><br />
            I was lucky enough to earn a software engineering position in Ames,
            where I learned how to properly be a software engineer. During my
            last few years of professional experience, I've learned common
            languages such as C#, Typescript, javascript. I commonly use
            technologies like node, .NET (Core and Framework), Angular, Vue3,
            MySQL / SQL to solve problems, and would say I excel in C# because
            of the similarities it has with Java, one of the first languages I
            learned.
            <br /><br />
            I love playing and developing games in my free time, as well as disc
            golfing (hence the company name!). If I'm not doing one of those
            things I'm probably spending time with my wife playing disc golf,
            playing video games, or playing with my dogs.
            <br /><br />
            To contact me with any general questions or to request a quote, hit
            the contact me button below!
          </p>
          <div class="col-12 mt-5 text-center">
            <router-link
              to="/contact"
              type="button"
              class="btn btn-secondary btn-lg text-white"
            >
              Contact Me!
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="what-i-offer"
    class="container-fluid px-4 py-5 bg-light d-flex justify-content-center"
  >
    <div class="col-xxl-8 col-12">
      <h2 class="pb-2 border-bottom">What I Offer</h2>
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div class="feature col">
          <h3 class="fs-2 text-body-emphasis">
            <i class="bi bi-globe2"></i> Website Development
          </h3>
          <p>
            From simple landing pages to fully functional storefronts, I've got
            you covered! In addition to helping you bring your website dreams to
            reality, I can help you get your entire web presence setup - helping
            you get the correct web hosting and domain needs.
          </p>
        </div>
        <div class="feature col">
          <h3 class="fs-2 text-body-emphasis">
            <i class="bi bi-phone"></i> App Development
          </h3>
          <p>
            Ever thought to yourself, "This would make a good app!"? I can help
            you! By contracting your work out to me, you can maintain full
            ownership of the intellectual property of your app, and I can help
            bring it to life!
          </p>
        </div>
        <div class="feature col">
          <h3 class="fs-2 text-body-emphasis">
            <i class="bi bi-code-slash"></i> Freelance Help
          </h3>
          <p>
            Have software that needs rescued? Not sure where to start? Need
            features or tests written? I can help!
          </p>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          <router-link
            to="projects"
            class="btn btn-secondary btn-lg text-white m-2"
            >See Projects</router-link
          >
          <router-link to="request" class="btn btn-secondary btn-lg text-white"
            >Request a Quote</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./core/Header.vue";

export default {
  name: "HomeComponent",
  components: {
    HeaderComponent,
  },
};
</script>
