<template>
  <HeaderComponent
    :section-class="'hero-section-request-quote'"
    :show-text="false"
    :header-text="'Request a Quote'"
  ></HeaderComponent>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="card mt-3 mb-3 shadow">
          <div class="card-body">
            <h3>Requesting a Quote</h3>
            <hr />
            <p>* Double check that your email is correct</p>
            <p>
              * Leave a brief explanation of what you want your project to be
              (specific content, ui/ux concerns and ideas, etc) - Don't worry if
              it doesn't fit! I will email you back for any extra information
              before quoting you!
            </p>

            <br /><br />
            <strong
              >Thank you for your interest! I'll get back to you ASAP.</strong
            >
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <ContactFormComponent
          :predefined-type="'Request Quote'"
          :disabled="true"
          :quote-form="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactFormComponent from "./shared/ContactForm.vue";
import HeaderComponent from "./core/Header.vue";

export default {
  name: "PricingComponent",
  components: {
    ContactFormComponent,
    HeaderComponent,
  },
};
</script>
