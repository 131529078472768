import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Projects from "./components/Projects.vue";
import ContactMe from "./components/Contact.vue";
import NotFound from "./components/NotFound.vue";
import Request from "./components/RequestQuote.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Patent Pending Studios - Software For Tomorrow",
      metaTags: [
        {
          name: "description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "og:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "author",
          content: "Keith Shimp",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "software developer, game developer, app developer, programming, game development, app development, portfolio, projects",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "og:image",
          content: "https://patentpendingstudios.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://patentpendingstudios.com",
        },
        {
          property: "og:site_name",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
      ],
    },
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: {
      title: "Patent Pending Studios - Projects",
      metaTags: [
        {
          name: "description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "og:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "author",
          content: "Keith Shimp",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "software developer, game developer, app developer, programming, game development, app development, portfolio, projects",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Patent Pending Studios - Projects",
        },
        {
          property: "og:image",
          content: "https://patentpendingstudios.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://patentpendingstudios.com",
        },
        {
          property: "og:site_name",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "Contact Me",
    component: ContactMe,
    meta: {
      title: "Patent Pending Studios - Contact",
      metaTags: [
        {
          name: "description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "og:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "author",
          content: "Keith Shimp",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "software developer, game developer, app developer, programming, game development, app development, portfolio, projects",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Patent Pending Studios - Contact",
        },
        {
          property: "og:image",
          content: "https://patentpendingstudios.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://patentpendingstudios.com",
        },
        {
          property: "og:site_name",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
      ],
    },
  },
  // {
  //   path: "/testimonials",
  //   name: "Testimonials",
  //   component: Testimonials,
  // },
  {
    path: "/request",
    name: "Request Quote",
    component: Request,
    meta: {
      title: "Patent Pending Studios - Request a Quote",
      metaTags: [
        {
          name: "description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "og:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "author",
          content: "Keith Shimp",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "software developer, game developer, app developer, programming, game development, app development, portfolio, projects",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Patent Pending Studios - Request a Quote",
        },
        {
          property: "og:image",
          content: "https://patentpendingstudios.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://patentpendingstudios.com",
        },
        {
          property: "og:site_name",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
      ],
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      title: "Patent Pending Studios - 404 - Not Found",
      metaTags: [
        {
          name: "description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "og:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
        {
          property: "author",
          content: "Keith Shimp",
        },
        {
          property: "robots",
          content: "index, follow",
        },
        {
          property: "language",
          content: "English",
        },
        {
          property: "keywords",
          content:
            "software developer, game developer, app developer, programming, game development, app development, portfolio, projects",
        },
        {
          property: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
        {
          property: "og:title",
          content: "Patent Pending Studios - 404 - Not Found",
        },
        {
          property: "og:image",
          content: "https://patentpendingstudios.com/logo.png",
        },
        {
          property: "og:url",
          content: "https://patentpendingstudios.com",
        },
        {
          property: "og:site_name",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:title",
          content: "Patent Pending Studios - Software For Tomorrow",
        },
        {
          property: "twitter:description",
          content:
            "Experienced software developer specializing in game development and app development. Experienced in C#, .NET Core / .NET Framework, Angular, PHP, SQL / MySQL, Vue3, and more.",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // routes to another page via anchor link if it exists
  scrollBehavior(to, from, savedPosition) {
    // If saved position is passed down, return saved position
    if (savedPosition) {
      return savedPosition;
    }

    // if hash is included, go to the element matching the anchor hash
    if (to.hash) {
      return { el: to.hash };
    }

    // Otherwise, return 0, 0
    return { top: 0, left: 0 };
  },
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
