import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/styles.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import router from "./router";
import VueGTag from "vue-gtag";

const app = createApp(App)
  .use(router)
  .use(
    VueGTag,
    {
      appName: "Patent Pending Studios",
      pageTrackerScreenviewEnabled: true,
      config: { id: "G-PQ2XWDWWC6" },
    },
    router
  )
  .mount("#app");

// app.config.errorHandler = (err, instance, info) => {
//     // report error to tracking services
// }
