<template>
  <HeaderComponent
    :section-class="'hero-section-contact'"
    :show-text="false"
    :header-text="'Contact Me'"
  ></HeaderComponent>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="card mt-3 mb-3 shadow">
          <div class="card-body">
            <h3>Contacting Me</h3>
            <hr />
            <p>* Double check that your email is correct</p>
            <p>
              * If you're leaving a testimonial, please leave your full name!
              Though I'll probably know who you are from your email :D
            </p>

            <br /><br />
            <strong
              >Thank you for your interest! I'll get back to you ASAP.</strong
            >
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <ContactFormComponent />
      </div>
    </div>
  </div>
</template>

<script>
import ContactFormComponent from "./shared/ContactForm.vue";
import HeaderComponent from "./core/Header.vue";

export default {
  name: "ContactComponent",
  components: {
    ContactFormComponent,
    HeaderComponent,
  },
};
</script>
