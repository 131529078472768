<template>
  <ModalComponent
    v-model="modalOpen"
    :value="modalOpen"
    @input="openModal(modalSrc, modalAlt)"
  >
    <div class="text-center col-12">
      <img class="w-100" :src="modalSrc" :alt="modalAlt" />
    </div>
  </ModalComponent>
  <HeaderComponent
    :section-class="'hero-section-projects'"
    :show-text="false"
    :header-text="'Projects'"
  ></HeaderComponent>
  <div class="album py-5 bg-body-tertiary">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Websites</h1>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Michael's Lyfescheme: Thumbnail"
              src="@/assets/images/lyfescheme.png"
            />
            <div class="card-body">
              <strong>Michael's Lyfescheme</strong>
              <p class="card-text">Business website for Michael's Lyfescheme</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/lyfescheme.png',
                        'Lyfescheme - Homepage'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="https://lyfescheme.com"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Abstracz: Thumbnail"
              src="@/assets/images/abstraczlogo.png"
            />
            <div class="card-body">
              <strong>Abstracz</strong>
              <p class="card-text">
                Website for the doctor and artist Shashi Kerai
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/abstracz.png',
                        'Abstracz - Homepage'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="https://abstracz.com"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Mile 20 Recruiting: Thumbnail"
              src="@/assets/images/mile20logo.png"
            />
            <div class="card-body">
              <strong>Mile 20 Recruiting - COMING SOON</strong>
              <p class="card-text">Business for Mile 20 recruiting</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/no-image.png',
                        'Mile 20 Recruiting - Homepage'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary disabled"
                    href="#"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Gary Eller: Thumbnail"
              src="@/assets/images/garyellerlogo.png"
            />
            <div class="card-body">
              <strong>Gary Eller</strong>
              <p class="card-text">
                A website for the amazing author Gary Eller.
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/ge.png',
                        'Gary Eller - Homepage'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="http://garyeller.com"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Patent Pending Studios: Thumbnail"
              src="@/assets/images/ppslogo.png"
            />
            <div class="card-body">
              <strong>Patent Pending Studios</strong>
              <p class="card-text">My personal business website</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/pps.jpg',
                        'Patent Pending Studios - Homepage'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="https://patentpendingstudios.com"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Catherine Miller Coaching: Thumbnail"
              src="@/assets/images/cmclogo.png"
            />
            <div class="card-body">
              <strong>Catherine Miller Coaching</strong>
              <p class="card-text">
                Business/personal website for Catherine Miller
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/cmc.PNG',
                        'Catherine Miller Coaching - Scheduling'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="https://catherinemillercoaching.com"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="4PAWD Creations: Thumbnail"
              src="@/assets/images/kmlogo.png"
            />
            <div class="card-body">
              <strong>4PAWD Creations</strong>
              <p class="card-text">
                Business website/storefront for 4PAWD Creations by Katie
                Marshall
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/no-image.png',
                        'No Image'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="https://4pawdcreations.com"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <h1>Apps / Software</h1>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Summit Storefront: Thumbnail"
              src="@/assets/images/summitstorefrontlogo.png"
            />
            <div class="card-body">
              <strong>Summit Storefront - COMING SOON</strong>
              <p class="card-text">
                Full stack storefront solution forked from vue storefront /
                built on vendure server. Fully extensible and user friendly.
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    @click="
                      openModal(
                        'https://patentpendingstudios.com/project-images/images/no-image.png',
                        'No Image'
                      )
                    "
                  >
                    View Screenshot
                  </button>
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary disabled"
                    href="#"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <h1>API Integrations</h1>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Summit Storefront: Thumbnail"
              src="@/assets/images/spinwheellogo.png"
            />
            <div class="card-body">
              <strong>Spinwheel.io</strong>
              <p class="card-text">
                Banking / financing debt API. Makes connecting users with debt
                relief easy.
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="https://spinwheel.io"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card shadow-sm">
            <img
              class="card-img-top"
              role="img"
              aria-label="Stripe: Thumbnail"
              src="@/assets/images/stripelogo.png"
            />
            <div class="card-body">
              <strong>Stripe</strong>
              <p class="card-text">
                Full financial infrastructure API for businesses
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <a
                    type="button"
                    class="btn btn-sm btn-outline-primary"
                    href="https://stripe.com/"
                    target="_blank"
                  >
                    Visit Site
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalComponent from "./core/Modal.vue";
import HeaderComponent from "./core/Header.vue";

export default {
  name: "ProjectsComponent",
  components: {
    ModalComponent,
    HeaderComponent,
  },
  data() {
    return {
      modalOpen: false,
      modalSrc: null,
      modalAlt: "alt",
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openModal(src, alt) {
      this.modalSrc = src;
      this.modalAlt = alt;
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>
