<template>
  <HeaderComponent
    :section-class="'hero-section-not-found'"
    :show-text="false"
    :header-text="'404 Not Found'"
  ></HeaderComponent>
  <div class="container-fluid">
    <div class="row text-center">
      <span
        >Oops, looks like this page doesn't exist! Go
        <router-link to="/">home</router-link>.</span
      >
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./core/Header.vue";

export default {
  name: "NotFoundComponent",
  components: {
    HeaderComponent,
  },
};
</script>
