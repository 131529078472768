<template>
  <div class="card mt-3 mb-3 shadow">
    <div class="card-header bg-dark text-white">{{ title }}</div>
    <div class="card-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
