<template>
  <footer class="footer sticky-sm-bottom py-3 text-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span class="text-muted"
            >&copy; 2023 - {{ new Date().getFullYear() }} Patent Pending Studios
            LLC
          </span>
        </div>
        <div class="col-12">
          <span><a class="link-primary">Privacy Policy</a></span>
        </div>
        <div class="col-12">
          <a href="https://github.com/keshimp">
            <img class="m-2" src="@/assets/svg/github.svg" />
          </a>
          <a href="https://twitter.com/PatentPend97885">
            <img class="m-2" src="@/assets/svg/twitter.svg" />
          </a>
          <a href="https://www.instagram.com/patentpendingstudios/">
            <img class="m-1" src="@/assets/svg/instagram.svg" />
          </a>
          <a href="mailto:keith@patentpendingstudios.com">
            <img class="m-1" src="@/assets/svg/mail.svg" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
