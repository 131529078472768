<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-scroll nav-menu">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img class="logo logo-large" src="@/assets/images/logo.png" />
      </router-link>
      <button
        class="navbar-toggler collapsed text-white"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navBarLinks"
        aria-controls="navBarLinks"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span class="icon-bar top-bar"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </button>
      <div id="navBarLinks" class="collapse navbar-collapse text-white">
        <ul class="navbar-nav nav-pills ms-auto text-center">
          <li class="pl-3 pr-3 nav-item">
            <router-link to="/projects" class="nav-link nav-text">
              Projects
            </router-link>
          </li>
          <li class="pl-3 pr-3 nav-item">
            <router-link to="/request" class="nav-link nav-text">
              Request Quote
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/testimonials" class="nav-link nav-text">
              Testimonials
            </router-link>
          </li> -->
          <li class="pl-3 pr-3 nav-item">
            <router-link to="/contact" class="nav-link nav-text">
              Contact
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarComponent",
};
</script>
